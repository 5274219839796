import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import TeacherQuality from "../components/teacherquality"
import CustomizedTutoring from "../components/customizedtutoring"
import WhyChooseUs from "../components/whychooseus"
import TopTutors from "../components/toptutors"
import Faq from "../components/FAQ"
import HowItWorks from "../components/howitworks"
import Pricing from "../components/pricing"
import Testimonials from "../components/testimonials"
// import BlogLatest from "../components/bloglatest"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data, location }) => {
  return (
    <Layout currPage="home" location={location}>
      <Helmet>
        <script src="/lib/jquery-3.4.1.min.js" />
      </Helmet>
      <Seo />
      <TeacherQuality />
      <CustomizedTutoring />
      <WhyChooseUs />
      <TopTutors images={data} />
      <Faq />
      <HowItWorks />
      <Pricing />
      <Testimonials />
    </Layout>
  )
}

export const query = graphql`
  query {
    picAngela: file(relativePath: { eq: "tutors/angela.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    picXiaoWang1: file(relativePath: { eq: "tutors/xiaowang1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    picXiaoLiu1: file(relativePath: { eq: "tutors/xiaoliu1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    picJessie1: file(relativePath: { eq: "tutors/jessie1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default IndexPage

// import React from "react"
// import { Location } from "@reach/router"
// import App from "./src/components/App"
import "bootstrap"

// const addScriptURL = url => {
//   const script = document.createElement("script")
//   script.src = url
//   // script.async = true;
//   script.type = "text/javascript"
//   document.body.appendChild(script)
// }

// const addScriptString = str => {
//     const script = document.createElement("script");
//     script.innerHTML = str;
//     script.type  = "text/javascript";
//     document.body.appendChild(script);
// };

const addScriptsToBodyEnd = () => {
  //this should really only be loaded for certain pages!!!!
  // addScriptURL("/js/main.js");
  // addScriptURL("/lib/bootstrap.bundle.min.js")
  // addScriptString("alert('haha')");
}

// export const onClientEntry = () => {
//     console.log("---onClientEntry---")
// }

export { wrapRootElement } from "./src/util/uselocalstorage"

// export const wrapRootElement = ({ element }) => {
//     return (
//         <Location>
//             {location => <App element={element} location={location} />}
//         </Location>
//     )
// }

export const onInitialClientRender = () => {
  // console.log("---onInitialClientRender--- (ReactDOM.render has executed)");
  window.addEventListener("load", function () {
    addScriptsToBodyEnd()
  })
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('---onRouteUpdate---');
  if (!prevLocation || location.pathname !== prevLocation.pathname) {
    addScriptsToBodyEnd()
  }
}

// export const onPostPrefetchPathname = () => {
//     /*
//      Called when prefetching for a pathname is successful.
//      Allows for plugins with custom prefetching logic.
//     */
//     console.log("---onPostPrefetchPathname---");
// }
//
//
// export const onPrefetchPathname = () => {
//     /*
//      Called when prefetching for a pathname is triggered.
//      Allows for plugins with custom prefetching logic.
//      */
//     console.log("---onPrefetchPathname---");
// }
//
// export const onServiceWorkerActive = () => {
//     /*
//      Inform plugins when a service worker has become active.
//      */
//     console.log("onServiceWorkerActive");
// }
//
// export const onServiceWorkerInstalled = () => {
//     /*
//      Inform plugins when a service worker has been installed.
//      */
//     console.log("onServiceWorkerInstalled");
// }
//
// export const onServiceWorkerRedundant = () => {
//     /*
//      Inform plugins when a service worker is redundant.
//      */
//     console.log("onServiceWorkerRedundant");
// }
//
// export const onServiceWorkerUpdateFound = () => {
//     /*
//      Inform plugins of when a service worker has an update available.
//      */
//     console.log("onServiceWorkerUpdateFound");
// }
//
// export const onServiceWorkerUpdateReady = () => {
//     /*
//      Inform plugins when a service worker has been updated in the background
//      and the page is ready to reload to apply changes.
//      */
//     console.log("onServiceWorkerUpdateReady");
// }
//
